import { NavLink } from '@remix-run/react'
import type { UserRole } from '@repo/db/schema'
import { Icon } from '@repo/ui/components/Icon.js'
import {
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
  SidebarSeparator,
  Sidebar as UISidebar
} from '@repo/ui/components/Sidebar.js'
import type { IconName } from '@repo/ui/icons/icons'
import { cn } from '@repo/ui/utils/utils'
import { useAuthenticatedUser } from '~/hooks/useAuthenticatedUser'
import { TOPBAR_HEIGHT } from './Topbar'

const Links: SidebarLink[] = [
  {
    name: 'Sessions',
    path: '/',
    icon: 'arrow-pointer',
    roles: ['admin']
  },
  {
    name: 'Apps',
    path: '/apps',
    icon: 'box-open',
    roles: ['admin']
  },
  {
    name: 'Users',
    path: '/users',
    icon: 'users',
    roles: ['admin']
  },
  {
    name: 'Settings',
    path: '/settings',
    icon: 'gear',
    roles: ['admin']
  },
  {
    name: 'My Data',
    path: '/user/me',
    icon: 'user',
    roles: ['restricted']
  }
]

export const Sidebar = () => {
  return (
    <UISidebar
      collapsible="icon"
      style={{ height: `calc(100svh - ${TOPBAR_HEIGHT})`, top: TOPBAR_HEIGHT }}
    >
      <SidebarContent>
        <SidebarLinks links={Links} />
      </SidebarContent>
      <SidebarRail />
    </UISidebar>
  )
}

type SidebarLink = {
  name: string
  path: string
  icon: IconName
  roles?: UserRole[]
  end?: boolean
}

export const SidebarLinks = ({ links }: { links: SidebarLink[] }) => {
  const user = useAuthenticatedUser()

  if (!user.role) return null

  return (
    <>
      <SidebarGroup>
        <SidebarGroupContent>
          <SidebarMenu>
            {links
              .filter((l) => !l.roles || l.roles.includes(user.role!))
              .map((link) => (
                <SidebarMenuItem key={link.name}>
                  <NavLink
                    to={link.path}
                    end={link.end}
                    className="block"
                    prefetch="intent"
                  >
                    {({ isActive }) => (
                      <SidebarMenuButton isActive={isActive}>
                        <Icon
                          name={link.icon}
                          className={cn(isActive && 'text-brand')}
                        />

                        <span>{link.name}</span>
                      </SidebarMenuButton>
                    )}
                  </NavLink>
                </SidebarMenuItem>
              ))}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>

      {user.isInternal ? (
        <>
          <SidebarSeparator className="opacity-50" />
          <SidebarGroup>
            <SidebarMenu>
              <SidebarMenuItem>
                <NavLink to="/admin" className="block">
                  {({ isActive }) => (
                    <SidebarMenuButton isActive={isActive}>
                      <Icon
                        name="shield"
                        className={cn(isActive && 'text-brand')}
                      />
                      <span>Admin</span>
                    </SidebarMenuButton>
                  )}
                </NavLink>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroup>
        </>
      ) : null}
    </>
  )
}
