import type { LoaderFunctionArgs } from '@remix-run/node'
import { Outlet, redirect, useRouteError } from '@remix-run/react'
import { userMutations } from '@repo/db/mutations/user'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import { ErrorCard } from '~/components/ErrorCard'
import { Layout } from '~/components/layout/Layout'
import { useIntercom } from '~/hooks/useIntercom'
import { requireAuth } from '~/services/auth.server'
import { getOrgTags } from './getOrgTags.server'

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const auth = await requireAuth(request, ['admin', 'restricted'])
  if (!auth.isOnboarded) throw redirect('/onboarding/survey')
  const [memberships, orgTags] = await Promise.all([
    userMutations.getMemberships(auth.userId),
    getOrgTags(auth.orgId)
  ])
  if (!memberships.length) throw redirect('/org')
  return { memberships, orgTags }
}

export default function AppLayoutRoute() {
  useIntercom()

  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export function ErrorBoundary() {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center h-full">
        <ErrorCard error={error} />
      </div>
    </Layout>
  )
}
