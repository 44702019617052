import { Link } from '@remix-run/react'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@repo/ui/components/AlertDialog.js'
import { Button } from '@repo/ui/components/Button.js'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@repo/ui/components/DropdownMenu.js'
import { LogoIcon } from '@repo/ui/components/Logo.js'
import { Separator } from '@repo/ui/components/Separator.js'
import { SidebarTrigger } from '@repo/ui/components/Sidebar.js'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@repo/ui/components/Tooltip.js'
import { UserAvatar } from '~/components/UserAvatar'
import { useAuthenticatedUser } from '~/hooks/useAuthenticatedUser'
import { UniversalSearch } from '../../routes/api.search/UniversalSearch'
import { ExtensionStatus } from './ExtensionStatus'
import { OrgPicker } from './OrgPicker'

export const TOPBAR_HEIGHT = '3.5rem'

export const Topbar = () => {
  const user = useAuthenticatedUser()

  return (
    <div
      className="flex items-center justify-between border-b px-5 overflow-x-auto shrink-0 fixed w-full z-20 bg-secondary"
      style={{ height: TOPBAR_HEIGHT }}
    >
      <div className="flex justify-start gap-4 items-stretch">
        <Tooltip>
          <TooltipTrigger asChild>
            <SidebarTrigger className="size-4 hover:bg-transparent place-self-center opacity-70" />
          </TooltipTrigger>

          <TooltipContent align="start" className="z-50">
            <div className="flex items-center gap-4 justify-between">
              <span>Toggle sidebar</span>
              <span className="text-xs text-muted-foreground font-mono tracking-widest">
                ⌘B
              </span>
            </div>
          </TooltipContent>
        </Tooltip>
        <div className="flex items-center justify-center">
          <Link to="/">
            <LogoIcon className="size-7" />
          </Link>
        </div>
        <Separator orientation="vertical" className="w-0.5 rotate-12" />
        <OrgPicker />
      </div>
      <div className="flex items-stretch gap-4">
        <UniversalSearch />

        <ExtensionStatus />

        <DropdownMenu>
          <DropdownMenuTrigger>
            <UserAvatar src={user.avatarUrl} className="size-7" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                  Log out
                </DropdownMenuItem>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle />
                  <AlertDialogDescription>
                    Your activity tracking will be disabled when you're logged
                    out, even if you have the extension installed.
                  </AlertDialogDescription>
                  <AlertDialogFooter>
                    <AlertDialogCancel />
                    <form method="POST" action="/logout">
                      <AlertDialogAction asChild>
                        <Button type="submit" variant="default">
                          Log out
                        </Button>
                      </AlertDialogAction>
                    </form>
                  </AlertDialogFooter>
                </AlertDialogHeader>
              </AlertDialogContent>
            </AlertDialog>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}
