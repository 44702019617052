import { Button } from '@repo/ui/components/Button.js'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@repo/ui/components/Dialog.js'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@repo/ui/components/DropdownMenu.js'
import { Icon } from '@repo/ui/components/Icon.js'
import { useState } from 'react'
import { Form } from '~/components/Form'
import { useAuthenticatedOrg } from '~/hooks/useAuthenticatedOrg'
import { useForm } from '~/hooks/useForm'
import { useMemberships } from '~/routes/_app/useMemberships'
import { CreateOrgForm } from '~/routes/api.organizations/CreateOrgForm'
import { SwitchOrganizationSchema } from '~/routes/api.organizations/routeConfig'

export const OrgPicker = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const org = useAuthenticatedOrg()
  const memberships = useMemberships()

  const form = useForm({
    schema: SwitchOrganizationSchema,
    action: '/api/organizations',
    onSuccess: () => setMenuOpen(false)
  })

  return (
    <>
      <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm" className="text-muted-foreground">
            <span className="font-medium ">{org.name}</span>
            <Icon name="chevrons-up-down" className="size-3.5" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" className="min-w-60">
          {memberships.map((membership) => (
            <Form key={membership.organization.id} {...form}>
              <DropdownMenuItem
                disabled={membership.organization.id === org.id}
                className="flex items-center gap-2"
                onSelect={(e) => {
                  e.preventDefault()
                  form.submit({ orgId: membership.organization.id })
                }}
              >
                <div className="size-4 flex items-center justify-center">
                  {membership.organization.id === org.id ? (
                    <Icon name="circle-check" size="sm" />
                  ) : null}
                </div>
                <div>
                  <div className="font-medium">
                    {membership.organization.name}
                  </div>
                  <div className="text-xs capitalize">{membership.role}</div>
                </div>
              </DropdownMenuItem>
            </Form>
          ))}
          <DropdownMenuSeparator />

          <DropdownMenuItem
            onSelect={() => setDialogOpen(true)}
            className="flex items-center gap-2"
          >
            <Icon name="circle-plus" size="sm" />
            <span>Create organization</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="max-w-sm">
          <DialogHeader>
            <DialogTitle>Create an organization</DialogTitle>
            <DialogDescription className="sr-only">
              Create a new organization
            </DialogDescription>
          </DialogHeader>
          <CreateOrgForm onSuccess={() => setDialogOpen(false)} />
        </DialogContent>
      </Dialog>
    </>
  )
}
